<template>
	<div>
		<div class="table-cont p-2">
			<div class="row align-items-center flex-column">
				<div class="dash-title col-md p-0 mt-0 mb-2">
					<h6 class="bold">جميع المنتجات</h6>
					<p class="mb-1">يمكنك إضافة فروع جديدة وإمكانيه تعديلها او حذفها</p>
				</div>

				<div class="form-group col-md p-2 m-0" style="background-color: #fff; border-radius: 5px">
					<div class="row align-items-center justify-content-between">
						<div class="col-5 p-0 password-cont">
							<input
								type="text"
								class="form-control"
								id="dataTableSearchInput1"
								aria-describedby="emailHelp"
								placeholder="كلمات مفتاحية"
								v-model="name"
								@input="getFilteredData()"
							/>
							<i class="fa fa-search color-gray"></i>
						</div>
						<div class="d-flex justify-content-start align-items-center" style="margin: 0 17px">
							<p style="margin: 4px">فلترة حسب</p>
							<select
								name=""
								id=""
								style="width: 70px; height: 38px !important"
								v-model="date"
								@change="getFilteredData()"
							>
								<option value="old" selected>الاقدم</option>
								<option value="recent">الاحدث</option>
							</select>
						</div>

						<div class="col p-0 text-align2">
							<router-link to="/addProduct"
								><button class="btn btn-warning color-white" style="font-size: 13px">
									<span class="dash-add"><i class="fa fa-plus"></i></span> اضافة منتج
								</button></router-link
							>
						</div>
					</div>
				</div>
			</div>

			<table
				id="dataTable1"
				data-order='[[ 1, "asc" ]]'
				data-page-length="25"
				class="table text-center table-striped table-ed"
				style="width: 100%"
			>
				<thead class="table-head">
					<tr>
						<th>رقم</th>
						<th>الاسم بالعربية</th>
						<th>الاسم بالانجليزية</th>
						<th></th>
					</tr>
				</thead>
				<tbody data-class-name="table-body ">
					<tr v-for="(product, index) in products" :key="product.id">
						<td>{{ index + 1 + first }}</td>
						<td>{{ product.name_ar }}</td>
						<td>{{ product.name_en }}</td>
						<td class="table-menu">
							<i @click="openTableMenu(index)" class="cp p-2 fa fa-ellipsis-h"></i>
							<div @click="openTableMenu(index)" class="menu-cont" v-if="showMenue[index]">
								<ul class="white-bg round7 pt-1 pb-1 shadow1">
									<li>
										<router-link :to="'/editProduct/' + product.id"
											><i class="fa fa-edit color1"></i> تعديل</router-link
										>
									</li>
									<li class="border-bottom"></li>
									<li>
										<button class="btn cp" @click.prevent="deleteProduct(product.id)">
											<i class="far fa-trash-alt color-red"></i> حذف
										</button>
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<Paginator
				v-if="products?.length > 0"
				:rows="perPage"
				:totalRecords="totalRecords"
				:page="currentPage"
				@page="onPageChange"
				class="paginator"
			/>
		</div>
	</div>
	<Toast />
</template>
<script>
import api from "@/core/api";
import Toast from "primevue/toast";
import Paginator from "primevue/paginator";

export default {
	name: "VendorDashboardProductsView",

	data() {
		return {
			showMenue: [],
			products: [],
			name: "",
			date: "old",
			first: 0, // Offset for pagination
			perPage: 10, // Number of products per page
			totalRecords: 0, // Total products count from the backend
			currentPage: 1, // Track the current page (starts at 1)
		};
	},
	methods: {
		openTableMenu(index) {
			if (this.showMenue?.length !== this.products?.length) {
				this.showMenue = Array(this.products?.length).fill(false);
			}
			this.showMenue = this.showMenue.map((_, i) => (i === index ? !this.showMenue[i] : false));
		},
		getFilteredData() {
			this.first = 0; // Reset to the first page when filtering
			this.currentPage = 1; // Reset to page 1
			this.getData();
		},
		async getData() {
			const token = localStorage.getItem("token");
			const headers = {Authorization: `Bearer ${token}`};
			const params = {
				search: this.name,
				date: this.date,
				page: this.currentPage, // PrimeVue uses zero-based page index
			};
			try {
				const res = await api.get("store/get-products", {headers, params});
				this.products = res.data.data.products;
				this.totalRecords = res.data.data.pagination.total; // Update total records
				console.log(res);
			} catch (error) {
				console.log(error);
				this.$toast.add({severity: "error", summary: "Error fetching    dfvdfv data", life: 4000});
			}
		},
		async deleteProduct(id) {
			const token = localStorage.getItem("token");
			const headers = {Authorization: `Bearer ${token}`};

			try {
				const res = await api.delete(`store/delete-product?product_id=${id}`, {headers});
				if (res.data.key === "success") {
					this.$toast.add({severity: "success", summary: res.data.msg, life: 4000});
					this.getData(); // Reload data after deletion
				} else {
					this.$toast.add({severity: "error", summary: res.data.msg, life: 4000});
				}
			} catch (error) {
				this.$toast.add({severity: "error", summary: "Error deleting product", life: 4000});
			}
		},
		onPageChange(event) {
			this.currentPage = event.page + 1; // PrimeVue provides zero-based page index
			this.getData(); // Fetch data for the new page
		},
	},
	mounted() {
		this.getData();
	},
	components: {
		Toast,
		Paginator,
	},
};
</script>
