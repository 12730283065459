<template>
	<div>
		<div class="table-cont p-2">
			<div class="row align-items-center flex-column">
				<div class="dash-title col-md p-0 mt-0">
					<h6 class="bold">الأقسام</h6>
					<p class="mb-1">يمكنك الاطلاع على جميع الأقسام من هنا</p>
				</div>

				<div class="form-group col-md p-2 m-2" style="background-color: #fff; border-radius: 5px">
					<div class="row align-items-center justify-content-between">
						<div class="col p-0 password-cont">
							<input
								type="text"
								class="form-control"
								id="dataTableSearchInput1"
								aria-describedby="emailHelp"
								placeholder="كلمات مفتاحية"
								v-model="name"
								@input="getFilteredData"
							/>
							<i class="fa fa-search color-gray"></i>
						</div>
						<div class="d-flex justify-content-start align-items-center">
							<p style="margin: 4px">فلترة حسب</p>
							<select
								name=""
								id=""
								style="width: 70px; height: 38px !important"
								v-model="date"
								@change="getFilteredData"
							>
								<option value="old" selected>الاقدم</option>
								<option value="recent">الاحدث</option>
							</select>
						</div>
						<div class="col p-0 text-align2">
							<router-link to="/categories">
								<button class="btn btn-warning color-white" style="font-size: 13px">
									<span class="dash-add"><i class="fa fa-plus"></i></span>
									إضافة قسم
								</button>
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<table
				id="dataTable1"
				data-order='[[ 1, "asc" ]]'
				data-page-length="25"
				class="table text-center table-striped table-ed"
				style="width: 100%"
			>
				<thead class="table-head">
					<tr>
						<th>رقم</th>
						<th>الاسم بالعربية</th>
						<th>الاسم بالانجليزية</th>
						<th></th>
					</tr>
				</thead>
				<tbody data-class-name="table-body">
					<tr v-for="(fin, index) in finance" :key="fin.id">
						<td>{{ index + 1 }}</td>
						<td>{{ fin.name_ar }}</td>
						<td>{{ fin.name_en }}</td>
						<td class="table-menu">
							<i @click="openTableMenu(index)" class="cp p-2 fa fa-ellipsis-h"></i>
							<div class="menu-cont" v-if="showTableActions[index]">
								<ul @click="openTableMenu(index)" class="white-bg round7 pt-1 pb-1 shadow1">
									<li>
										<router-link :to="'editMenue/' + fin.id" class="btn">
											<i class="fa fa-edit color1"></i> تعديل
										</router-link>
									</li>
									<li>
										<button class="btn" @click="deleteFinance(fin.id)">
											<i class="far fa-trash-alt color-red"></i> حذف
										</button>
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="4">
							<Paginator
								v-if="finance?.length > 0"
								:rows="perPage"
								:totalRecords="totalRecords"
								:page="currentPage"
								@page="pageClickHandler"
								class="paginator"
							/>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>

<script>
import api from "@/core/api";
import Paginator from "primevue/paginator";

export default {
	name: "VendorDashboardFinanceView",
	data() {
		return {
			showTableActions: [],
			finance: [],
			name: "",
			date: "old", // Default filter
			perPage: 10,
			totalRecords: 0,
			currentPage: 0,
		};
	},
	components: {
		Paginator,
	},
	mounted() {
		this.getFilteredData();
	},
	methods: {
		// Fetch filtered data
		async getFilteredData() {
			const token = localStorage.getItem("token");
			const headers = {Authorization: `Bearer ${token}`};

			try {
				const response = await api.get(`store/get-menus?search=${this.name}&date=${this.date}`, {
					headers,
					params: {
						page: this.currentPage + 1,
					},
				});
				this.finance = response.data.data.menus;
				this.totalRecords = response.data.data.pagination.total;
			} catch (error) {
				console.error("Error fetching data:", error);
				this.$toast.add({severity: "error", summary: "Failed to load data", life: 4000});
			}
		},
		// Delete a menu
		async deleteFinance(id) {
			const token = localStorage.getItem("token");
			const headers = {Authorization: `Bearer ${token}`, lang: "ar"};

			try {
				const response = await api.delete(`store/delete-menu?menu_id=${id}`, {headers});
				if (response.data.key === "success") {
					this.$toast.add({severity: "success", summary: response.data.msg, life: 4000});
					this.getFilteredData();
				} else {
					this.$toast.add({severity: "error", summary: response.data.msg, life: 4000});
				}
			} catch (error) {
				console.error("Error deleting menu:", error);
				this.$toast.add({severity: "error", summary: "Failed to delete menu", life: 4000});
			}
		},
		// Open/close table menu
		openTableMenu(index) {
			this.showTableActions = this.showTableActions.map((_, i) => (i === index ? !this.showTableActions[i] : false));
		},
		// Handle pagination
		pageClickHandler(page) {
			this.currentPage = page.page;
			this.getFilteredData();
		},
	},
};
</script>

<style lang="scss" scoped>
/* Add any missing styles here */
.table-cont {
	overflow: auto;
	background-color: #f9f9f9;
	border-radius: 8px;
}
.table-head {
	background-color: #f4f4f4;
}
.cp {
	cursor: pointer;
}
</style>
